<template>
  <div class="login-page">
    <div class="login-page__title">用户登录</div>
    <van-cell-group>
      <van-form validate-first>
        <van-field
          v-model="tel"
          name="phone"
          type="tel"
          label="手机号"
          maxlength="11"
          :rules="[{ required: true, message: '请输入手机号' }, { pattern: phoneReg, message: '手机号格式错误' }]"
        />
        <van-field
          v-model="vCodeText"
          center
          clearable
          label="图形验证码"
          placeholder="请输入图形验证码"
          block
          class="sms-btn"
          maxlength="4"
        >
          <template #button>
            <van-button size="small" type="primary" @click="handleChangeVCode" v-if="!vaildTel">获取验证码</van-button>
            <van-image :src="vCode" @click="handleChangeVCode" v-else />
          </template>
        </van-field>
        <van-field
          v-model="sms"
          center
          clearable
          label="短信验证码"
          placeholder="请输入短信验证码"
          block
          class="sms-btn"
        >
          <template #button>
            <van-button color="#ee0a24" size="small" type="primary" @click="handleSendSms" :disabled="sendSmsDisabled || sendSmsRequesting">{{ smsBtnText }}</van-button>
          </template>
        </van-field>
      </van-form>
    </van-cell-group>
    <van-button type="primary" color="linear-gradient(to right, #ff6034, #ee0a24)" block class="login-btn" @click="handleLogin">登录</van-button>
  </div>
</template>

<script>
import { BASE_URL, sendSms, login } from '../api'
import { loginSession } from '../utils/login'
export default {
  name: 'login-page',
  data() {
    return {
      tel: '',
      sms: '',
      vCodeText: '',
      sendSmsDisabled: false,
      sendSmsRequesting: false,
      smsBtnInterval: 0,
      vaildTel: false,
      count: 60,
      vCode: '',
      phoneReg: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    }
  },
  methods: {
    handleLogin() {
      // 验证短信
      if (!this.sms) {
        this.$toast('请输入短信验证码')
        return
      }
      sendSms({ phone: this.tel, code: this.sms }).then(() => {
        // 短信验证通过 进行登录操作
        login({ phone: this.tel }).then(res => {
          // 登录成功
          const { token } = res

          loginSession(token, this.tel)

          const { from, hcatid, hid, pcatid, pid } = this.$route.query
  
          switch(from) {
            case 'bookconfirm':
              this.$router.replace({ name: from, query: { hcatid, hid, pcatid, pid } })
              return
            case 'mine':
              this.$router.replace({ name: 'mine' })
              return
            default:
              this.$router.replace({ name: 'mine' })
          }
        })
      })
    },
    async handleSendSms() {
      if (this.sendSmsDisabled) return
      if (this.sendSmsRequesting) return
      if (!this.phoneReg.test(this.tel)) {
        this.$toast('手机号格式有误')
        return
      }
      if (!this.vCodeText) {
        this.$toast('请输入图形验证码')
        return
      }
      
      this.sendSmsRequesting = true

      sendSms({ phone: this.tel, vcode: this.vCodeText}).then(() => {
        this.sendSmsDisabled = true
        this.$toast('验证码已发送')
        this.smsBtnInterval = setInterval(() => {
          if (this.count > 0) {
            this.count--
          } else {
            clearInterval(this.smsBtnInterval)
            this.count = 60
            this.sendSmsDisabled = false
          }
        }, 1000)
      }).catch(() => {
        this.handleChangeVCode()
      }).finally(() => {
        this.sendSmsRequesting = false
      })
    },
    handleChangeVCode() {
      if (!this.phoneReg.test(this.tel)) {
        this.$toast('手机号格式有误')
        this.vaildTel = false
        return
      }
      this.vaildTel = true
      const vCodeRandom = Math.random()
      this.vCodeText = ''
      this.vCode = `${BASE_URL}/index.php?m=Api&c=Checkcode&f=index&refresh=1&code_len=4&type=${this.tel}&r=${vCodeRandom}`
    }
  },
  computed: {
    smsBtnText() {
      return this.sendSmsDisabled ? this.count + 's' : '发送验证码'
    },
  },
  beforeDestroy() {
    clearInterval(this.smsBtnInterval)
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  padding-top: 100px;

  &__title {
    text-align: center;
    margin: 0 auto 40px;
    font-size: 34px;
    letter-spacing: 1px;
    color: #666;
  }

  .login-btn {
    width: 360px;
    margin: 40px auto;
  }

  .sms-btn {
    // width: 40px;
  }
}
</style>