<template>
  <div class="vip-guide">
    <div class="content">
      <div class="title">百动柏纳仕会员尊享权益</div>
      <div class="rights-list">
        <div
          class="rights-list-item"
          v-for="item of rightsList"
          :key="item.title"
        >
          <div class="rights-list-item__icon">
            <van-image
              :src="item.icon"
              lazy-load
            />
            <div class="rights-list-item__value">{{ item.value }}</div>
          </div>
          <div class="rights-list-item__title">{{ item.title }}</div>
          <div class="rights-list-item__label">{{ item.label }}</div>
        </div>
      </div>
      <!-- <van-collapse v-model="activeNames">
        <van-collapse-item
          v-for="item of rightsList"
          :key="item.title"
          :title="item.title"
          :name="item.title"
          :value="item.value"
          title-class="rights-title van-ellipsis"
          value-class="van-ellipsis"
        >
          <template #title>
            <van-icon :name="item.icon" />
            {{ item.title }}
          </template>
          <div>{{ item.label }}</div>
        </van-collapse-item>
      </van-collapse> -->
    </div>
    <van-submit-bar :price="price" button-text="立即加入" suffix-label="/年" label="会员年费"></van-submit-bar>
  </div>
</template>

<script>
export default {
  name: 'vip-guide',
  data() {
    return {
      price: 199900,
      activeNames: [],
      rightsList: [
        {
          title: '高端酒店免费入住',
          value: '银行消费满额送',
          label: '全国高端酒店免晚住宿费（限参加银行活动达标即送）',
          icon: require("@/assets/vip/icon1.png")
        },
        {
          title: '亚朵8.2折预订',
          value: '不限次',
          label: '亚朵旗下酒店8.2折预订含免费双早',
          icon: require("@/assets/vip/icon2.png")
        },
        {
          title: '华夏航空金卡会籍',
          value: '本人匹配',
          label: '华夏航空金卡/银卡会籍免费匹配',
          icon: require("@/assets/vip/icon7.png")
        },
        {
          title: '全球奢华酒店礼遇',
          value: '不限次',
          label: '全球奢华酒店住三付二起及酒店消费抵扣100美金、含双早等奖励优惠',
          icon: require("@/assets/vip/icon3.png")
        },
        {
          title: '热门度假酒店直补',
          value: '全年6次',
          label: '乐领-诺图格、诺尔丹、康藤·帐篷营地等顶级国内热门野奢营地以及旅游目的地等度假酒店补贴优惠预订直连',
          icon: require("@/assets/vip/icon5.png")
        },
        {
          title: '高端酒店游泳健身',
          value: '全年12次',
          label: '全国高端酒店游泳健身、马术、高尔夫、滑冰、SPA、星级酒店下午茶五折尊享',
          icon: require("@/assets/vip/icon4.png")
        },
        {
          title: '星级酒店自助餐',
          value: '全年6次',
          label: '全国星级酒店自助餐二人同行一人免单',
          icon: require("@/assets/vip/icon3.png")
        },
        {
          title: '赛事购门票预订',
          value: '全年6次',
          label: '国际赛事门票预订及购票优惠',
          icon: require("@/assets/vip/icon6.png")
        },
        {
          title: '中免购物会员',
          value: '本人匹配',
          label: '中免海南离岛免税购物网上商城会员购物资格无需离岛记录、下单一件包邮购物额度2.6万/年',
          icon: require("@/assets/vip/icon8.png")
        },
        {
          title: '生活类折扣券',
          value: '不限次',
          label: '覆盖出行、阅读、视频、音乐、运动、办公六大类品牌折扣享会员权益',
          icon: require("@/assets/vip/icon9.png")
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.vip-guide {
  padding-bottom: 30px;

  .content {
    width: 350px;
    margin: 0 auto;

    .title {
      font-size: 26px;
      text-align: center;
      font-weight: 500;
      color: #333;
      margin-top: 15px;
      margin-bottom: 15px;
      padding-top: 30px;
      overflow: hidden;
    }

    .rights-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      &-item {
        width: 30%;
        text-align: center;
        margin-bottom: 10px;
        
        &__icon {
          position: relative;
        }

        &__title {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        &__value {
          font-size: 10px;
          color: #ff6034;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 15px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__label {
          font-size: 10px;
          color: #aaa;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.rights-title {
  flex: 2;
}
</style>